import React from 'react';
import CompanyLogo from '@active-channel/images/header-logo.svg';
import Link from '@components/base_link/component';
import PropTypes from 'prop-types';
import Container from '@components/ui_container/component';
import { useLocation } from 'react-router-dom';

import './styles.scss';

const LogoHeader = (props) => {
  const location = useLocation();
  const { pathname } = location;
  let path;

  /*
   * Hacky temporary solution to make the header logo clickable
   * Will create a ticket to look for better solution
   */
  switch (true) {
  case pathname.includes('/dashboard/bruna/'):
    path = pathname.split('/').slice(0, -1)
      .join('/');
    break;
  case pathname.includes('/dashboard/readshop/'):
    path = pathname.split('/').slice(0, -1)
      .join('/');
    break;
  default:
    path = pathname;
    break;
  }

  return (
    <header className="logo-header">
      <Container>
        <div className="flex-container">
          <Link to={path} className="company-logo desktop-logo">
            <CompanyLogo />
          </Link>
        </div>
      </Container>
    </header>
  );
};

LogoHeader.propTypes = {
  store: PropTypes.object,
  path: PropTypes.string,
};
export default LogoHeader;
