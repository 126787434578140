import { CMP_USP_MAIN, CMP_USP_LINKS, CMP_NOTIFICATION_BAR, CMP_SALE_BAR } from '../../constants';

class HeaderModel {
  constructor(components = []) {
    this.menuItems = components.filter((c) => {
      return ![CMP_USP_MAIN, CMP_USP_LINKS, CMP_NOTIFICATION_BAR, CMP_SALE_BAR].includes(c['component-id']);
    }) || [];

    this.mainUSPs = components.find(c => c['component-id'] === CMP_USP_MAIN)?.components || [];

    this.uspLinks = components.find(c => c['component-id'] === CMP_USP_LINKS)?.components || [];

    this.notificationBar = components.find(c => c['component-id'] === CMP_NOTIFICATION_BAR) || {};

    this.saleBar = components.find(c => c['component-id'] === CMP_SALE_BAR) || {};
  }
}

export default HeaderModel;
