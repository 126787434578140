import { checkFetchReturnStatus, restCall, formBodyUrlEncode, translateToJson } from './fetch.js';
import { ISH_ID } from '@config';
import { setCookie } from '@utils';

/**
 * The config component for the Intershop REST API
 * help: https://support.intershop.de/kb/index.php/Display/28R094
 * @type {{url: {base: string, activeBasket: string, addToBasket: function(*): string}}}
 * @class restapi
 */
class restapi {
  constructor({ apiUrl } = {}) {
    let currentDomain = '';
    let subdomain = '';
    if (typeof document !== 'undefined') {
      currentDomain = location.host;
      subdomain = currentDomain.split('.')[0];
    }

    const domainPrefix = (/localhost|local./.test(currentDomain) ? '' : API_PREFIX);
    const isLocalBackendImage = (subdomain === 'be');
    const localBackendApiUrl = 'https://local.bruna.nl';
    const regularApiUrl = apiUrl || API_PREFIX || domainPrefix;
    this._prefix = isLocalBackendImage ? localBackendApiUrl : regularApiUrl;

    this._siteDomain = ISH_ID;
    this._baseUrl = `${this._prefix}/INTERSHOP/rest/WFS/${this._siteDomain}/-/`;

    this._odsBaseUrl = `${this._prefix}/ods/`;
    this._odsSearchBaseUrl = `${this._prefix}/odssearch/`; // used for searching and filtering
  }

  /**
   * The base url where the REst api is running
   *
   * @returns {string}
   */
  get baseUrl() {
    return this._baseUrl;
  }

  set baseUrl(baseUrl) {
    this._baseUrl = baseUrl;
  }

  get odsBaseUrl() {
    return this._odsBaseUrl;
  }

  get odsSearchBaseUrl() {
    return this._odsSearchBaseUrl;
  }

  /**
   * Get the current version of the storefront from the server
   */
  getVersion() {
    return restCall(`/app/version`).then(res => res.json());
  }

  /**
   * Get the active basket
   * https://support.intershop.de/kb/index.php/Display/Y25878
   *
   * @param {integer|string} basketId
   * @returns {Promise<any>}
   */
  getSpecificBasket(basketId) {
    const url = `${this.baseUrl}tbabasket/${basketId}`;
    return restCall(url,
      {
        method: 'GET',
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .then((response) => {
        this.basketId = response.id;
        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get the active basket
   * https://support.intershop.de/kb/index.php/Display/Y25878
   *
   * @returns {Promise<any>}
   */
  getActiveBasket({ extendedBasket = false, origin = 'unset' } = {}) {
    const minified = (document.body.id === 'BasketPage') ? false : !extendedBasket;
    const query = `?minified=${minified}&origin=${origin}`;
    const url = `${this.baseUrl}tbabasket/-${query}`;
    return restCall(url,
      {
        method: 'GET',
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .then((response) => {
        this.basketId = response.id;
        return response;
      })
      .catch(this.handleError);
  }

  /**
   * The api url where to get the localized texts
   *
   * @returns {string}
   */
  get localizedTextUrl() {
    return `${this.baseUrl}localizedText`;
  }

  /**
   * Get pagelet components from the Backend by its components ids
   *
   * @param {string} ids - string comma separated
   * @returns {Promise}
   */
  getComponent({ ids }) {
    if (!ids) {
      return Promise.reject({ msg: 'please specify (a) componentId(s)' });
    }

    // e.g. ?componentIds=cmp_ecinl_privacy_policy,cmp_ecinl_payment_bottom_msg
    const url = `${this.baseUrl}tbacontent/pagelets?componentIds=${ids}`;

    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .then((response) => {
        if (!!response.elements) {
          return response.elements;
        }
        return response;
      })
      .catch(this.handleError);
  }

  /**
   * Get localized texts from the Backend by its key
   *
   * @param {string} ids - string comma separated
   * @returns {Promise}
   */
  getLocalizedText = ({ ids }) => {
    if (!ids) {
      return Promise.reject({ msg: 'please specify (a) componentId(s)' });
    }

    const url = `${this.localizedTextUrl}?localizedText=${ids}`;

    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .then((response) => {
        if (!!response.localizations) {
          return response.localizations;
        }
        return response;
      })
      .catch(this.handleError);
  };

  /**
   * Handles the error
   *
   * @param {Error} error
   */
  handleError = (error) => {
    if (typeof this.errorFn === 'function') {
      this.errorFn(error);
    }
    return Promise.reject(error);
  };

  /**
   * Bind an error function to this class
   *
   * @param {*} errorFn
   */
  setErrorFunction(errorFn) {
    this.errorFn = errorFn;
  }

  /**
   * The url where to add a item to the basket
   *
   * @param {string|number} basketId
   * @returns {*}
   */
  addToBasketUrl(basketId) {
    if (!basketId) {
      return false;
    }

    return `${this.baseUrl}tbabasket/${basketId}/tbaitems`;
  }

  /**
   * Add an item with sku to the basket with basketId
   *
   * @param {Object} obj
   * @param {string|number} obj.basketId
   * @param {string} obj.sku
   * @param {number} obj.quantity
   * @param {reference} obj.string
   * @param {boolean} obj.expressOrder
   *
   * @returns {Promise<any>}
   */
  addItemToBasket({ basketId, sku, quantity = 1, reference, amount, expressOrder }) {
    if (!basketId || !sku) {
      return Promise.reject({ msg: 'No basketId or SKU' });
    }

    // Only applies to blackhawk giftcard, which accepts a manual amount
    let amountAttribute;
    if (amount) {
      amountAttribute = {
        price: {
          value: parseFloat(amount),
        },
      };
    }

    const addToBasketUrl = this.addToBasketUrl(basketId);
    const item = {
      sku,
      quantity: {
        value: 1,
      },
      ...!!reference && { greetingMessage: reference },
      ...amountAttribute,
    };
    const items = Array(parseInt(quantity, 10)).fill(item);
    const name = expressOrder ? 'expressOrder' : undefined;

    return restCall(addToBasketUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ elements: items, name }),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all products on users wish-list
   *
   * @returns {Promise}
   */
  getWishlist({ amount = 999, offset = 0 } = {}) {
    const endpoint = `${this.baseUrl}tbacustomers/-/wishlists/-/paginated?amount=${amount}&offset=${offset}`;

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Get an array of Skus from the wish-list
   *
   * @returns {array}
   */
  getWishlistSkus() {
    const endpoint = `${this.baseUrl}tbacustomers/-/wishlists/-/skus`;

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Add item to wish-list
   *
   * @param {string} sku
   * @returns {Promise}
   */
  addToWishlist(sku) {
    if (!sku) {
      return Promise.reject({ msg: 'No SKU' });
    }
    return restCall(`${this.baseUrl}tbacustomers/-/wishlists/-/products/${sku}`, {
      method: 'POST',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .catch(this.handleError);
  }

  /**
   * Remove item from wish-list
   *
   * @param {string} sku
   * @returns {Promise}
   */
  removeFromWishlist(sku) {
    if (!sku) {
      return Promise.reject({ msg: 'No SKU' });
    }

    return restCall(`${this.baseUrl}tbacustomers/-/wishlists/-/products/${sku}`, {
      method: 'DELETE',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .catch(this.handleError);
  }

  /**
   * Set the set Shipping And Invoice address
   *
   * @param {Object} address
   * @param {string|number} basketId
   * @returns {Promise}
   */
  setShippingAndInvoiceAddress(address, basketId) {
    if (!address || !address.id) {
      return Promise.reject({ msg: 'address or basketId' });
    }

    const updateBody = {
      commonShipToAddress: {
        urn: address.urn,
      },
      invoiceToAddress: {
        urn: address.urn,
      },
    };

    return restCall(`${this.baseUrl}baskets/${basketId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updateBody),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Set the shipping address
   *
   * @param {Object} address
   * @param {string|number} basketId
   * @returns {Promise}
   */
  setShippingAddress(address, basketId) {
    if (!address || !address.id) {
      return Promise.reject({ msg: 'No addres or address id' });
    }

    const updateBody = {
      commonShipToAddress: {
        urn: address.urn,
      },
      invoiceToAddress: {
        urn: address.urn,
      },
    };

    return restCall(`${this.baseUrl}baskets/${basketId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updateBody),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all addresses assigned to user
   *
   * @returns {Promise}
   */
  getAddresses() {
    const endpoint = `${this.baseUrl}customers/-/addresses`;

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all details of assigned addresses
   *
   * @param {string} uri
   * @returns {Promise}
   */
  getAddressDetail(uri) {
    const endpoint = this.baseUrl + uri.replace(`${this._siteDomain}/-/`, '');

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Update an address
   *
   * @param {Object} address
   * @returns {Promise}
   */
  updateUserAddress(address = undefined) {
    if (!address || !address.id) {
      return Promise.reject({ msg: 'address or id' });
    }

    return restCall(`${this.baseUrl}customers/-/addresses/${address.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(address),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Update user profile
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  updateUserProfile(payload) {
    return restCall(`${this.baseUrl}tbacustomers/-`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Get user profile
   *
   * @returns {Promise}
   */
  getUserProfile() {
    const endpoint = `${this.baseUrl}tbacustomers/-/`;
    return restCall(endpoint, {
      method: 'GET',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(response => response.json())  // translate to json
      .catch(this.handleError);
  }

  /**
   * Update user phonenumber
   *
   * @param {String} phoneMobile
   * @returns {Promise}
   */
  updateUserPhoneNumber(phoneMobile) {
    return restCall(`${this.baseUrl}tbacustomers/-/phonenumber`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(phoneMobile),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Create an new address
   *
   * @param {Object} address
   * @returns {Promise}
   */
  createAddress(address) {
    if (!address) {
      return Promise.reject({ msg: 'No address' });
    }

    return restCall(`${this.baseUrl}customers/-/addresses`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(address),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Delete an address
   *
   * @param {Object|boolean} address
   * @returns {Promise}
   */
  deleteAddress(address = false) {
    if (!address || !address.id) {
      return Promise.reject({ msg: 'No address' });
    }

    return restCall(`${this.baseUrl}customers/-/addresses/${address.id}`, {
      method: 'DELETE',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .catch(this.handleError);
  }

  /**
   * Get the address details from a provided postalCode + houseNumber combination
   *
   * @param {string} postalCode
   * @param {string} houseNumber
   * @returns {Promise}
   */
  getAddressFromPostalCode(postalCode, houseNumber) {
    const endpoint = `${this.baseUrl}addressDetails?postCode=${postalCode}&houseNo=${houseNumber}`;

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Validate a giftcard code
   * User doesn't have to be logged in to do so
   *
   * @param {string} code
   * @returns {Promise}
   */
  giftcardValidateCode(code, token) {
    return restCall(`${this.baseUrl}intersolve/${code}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'form-token': token,
      },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Validate coupon code
   * User doesn't have to be logged in to do so
   *
   * @param {string} code
   * @returns {Promise}
   */
  intersolvePrefixes() {
    return restCall(`${this.baseUrl}intersolve/prefixes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Validate a coupon code
   * User doesn't have to be logged in to do so
   *
   * @param {string} token
   * @param {object} payload
   * @returns {Promise}
   */
  validateCoupon(payload, token) {
    return restCall(`${this.baseUrl}code/promotion`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'form-token': token,
      },
      credentials: 'omit',
      body: JSON.stringify(payload),
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json // translate to json
      .catch(this.handleError);
  }

  /**
   * Validate a campaign code
   * User doesn't have to be logged in to do so
   *
   * @param {string} cardType
   * @param {string} code
   * @returns {Promise}
   */

  campaignValidateCode(payload) {
    return restCall(`${this.baseUrl}card/add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
      body: JSON.stringify(payload),
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json() || {}) // translate to json
      .catch(this.handleError);
  }

  /**
   * Validates a coupon code
   *
   * @param {string} code
   * @param {string|number} basketId
   * @param {boolean} encrypted
   * @returns {Promise}
   */
  couponValidateCode({ type = 'other', code, id, encrypted = false, token = undefined, securityCode = '' }) {
    if (!id) {
      return Promise.reject({ msg: 'Missing basket ID' });
    }
    return restCall(`${this.baseUrl}tbabasket/${id}/tbagiftcard?encrypted=${encrypted}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'form-token': token,
      },
      credentials: 'omit',
      body: JSON.stringify({
        code,
        giftCardType: type,
        attributes: {
          securityCode,
        },
      }),
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Remove a coupon code from a payment id
   *
   * @param {string|number} paymentID
   * @param {string|number} basketId
   * @returns {Promise}
   */
  removeCoupon(paymentID, basketId) {
    const body = {
      name: 'INTERSOLVE',
      type: 'Payment',
      id: paymentID,
    };

    return restCall(`${this.baseUrl}tbabasket/${basketId}/tbagiftcard`, {
      method: 'DELETE',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Delete all giftcards from the basket
   *
   * @param {string|number} basketId
   */

  deleteGiftcards(basketId) {
    return restCall(`${this.baseUrl}tbabasket/${basketId}/tbagiftcard/all`, {
      method: 'DELETE',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  /**
   * Checks the availability of an email address on new user registration step 1
   *
   * @param {string} email
   * @returns {Promise}
   */
  checkEmailAvailability(email) {
    return restCall(`${this.baseUrl}tbacustomers/checkUserExists?login=${encodeURIComponent(email)}`, {
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  checkEmailForSubscription(email) {
    const url = `${this.baseUrl}tbasubscriptions/subscribeToNewsletter`;

    return restCall(url, {
      method: 'POST',
      body: email,
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  updatePromisedeliveryDates() {
    const url = `${this.baseUrl}tbabasket/updatepromisedeliverydates`;

    return restCall(url, {
      method: 'POST',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  /**
   * Creates a new private customer
   *
   * @param {Object} user
   * @param {?string|?number} basketID
   * @returns {Promise}
   */
  createNewUser(user, basketID = null) {
    const headers = {
      'Content-Type': 'application/json',
      'secret-authentication-token': 'UnserGeheimerToken123!',
      ...(basketID ? { BasketID: basketID } : {}),
    };

    return restCall(`${this.baseUrl}tbacustomers/webshop`, {
      method: 'POST',
      headers,
      body: JSON.stringify(user),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  storeToken(user) {
    setCookie('jwt', user.token);
    return user;
  }

  /**
   * Authenticates the user by token
   *
   * @param {Object} token
   * @param {?string|?number} basketID
   * @returns {Promise}
   */
  authenticateUser(token, basketID = null, guestCustomer = false, saveCredentials = false) {
    // guest customer
    if (guestCustomer) {
      return restCall(`${this.baseUrl}tbacustomers/guestcustomer`, {
        method: 'GET',
        Authorization: `Basic ${token}`,
        credentials: 'omit',
      })
        .then(checkFetchReturnStatus) // status check
        .then(translateToJson) // translate to json
        .catch(this.handleError);
    }

    const headers = {
      'Access-Control-Request-Headers': 'Content-Type, Authorization, BasketID',
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
      ...(basketID ? { BasketID: basketID } : {}),
    };
    const setCredentials = !!saveCredentials ? '/?extendedLogin=true' : '';
    return restCall(`${this.baseUrl}tbacustomers/-${setCredentials}`, {
      method: 'GET',
      headers,
      credentials: 'omit',
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .then(this.storeToken)
      .catch(this.handleError);
  }

  /**
   * Request a link to reset the user password
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  resetPassword(payload) {
    const formBody = formBodyUrlEncode(payload);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };

    const email = Object.values(payload).toString();
    const encodedEmail = encodeURIComponent(email);

    const url = `${this._baseUrl}tbacustomers/forgotPassword?login=${encodedEmail}`;
    return restCall(url, {
      method: 'PUT',
      headers,
      body: formBody,
      credentials: 'omit',
    }, false)
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  validateNewPasswordLink = ({ uid, hash }) => {
    return restCall(`${this.baseUrl}tbacustomers/newPassword?uid=${uid}&hash=${hash}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  };

  /**
   * Resets the user password
   *
   * @param {Object} payload
   * @returns {Promise}
   */
  setNewPassword(payload) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return restCall(`${this.baseUrl}tbacustomers/updatePassword`, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
      credentials: 'omit',
    }, false)
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * returns all bookarang gifts
   *
   * @param {string} answers
   * @returns {Promise<any | never>}
   */
  getBookarangGifts(answers) {
    return restCall(`${this.baseUrl}bookarangGifts/getGifts/${answers}`)
      .then(translateToJson); // translate to json
  }

  /**
   * Return all ramsj products
   *
   * @returns {Promise}
   */
  getTotalRamsjProducts() {
    return restCall(`${this.baseUrl}categories/promo/ramsj/products?amount=0`, {
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Creates a new guest user
   *
   * @param {string} login
   * @param {Array} cards
   * @returns {Promise}
   */
  createGuestUser(login, cards = []) {
    const body = {
      cards,
      credentails: { // typo on serverside
        type: 'Customer',
        login,
      },
    };

    return restCall(`${this.baseUrl}tbacustomers/guest`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
      credentials: 'omit',
    }, false)
      .then(checkFetchReturnStatus)
      .then(translateToJson)
      .catch(this.handleError);
  }

  /**
   * Get a list of all credits assignes to the logged-in user
   *
   * @returns {Promise}
   */
  getGiftcards() {
    const url = `${this.baseUrl}tbapayments/giftcard`;
    return restCall(url, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Assign a giftcard to the logged-in user
   *
   * @param {string} code
   * @returns {Promise}
   */
  setGiftcard(code) {
    const url = `${this.baseUrl}tbapayments/giftcard?code=${code}`;
    return restCall(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * set the status of firstLogin to true.
   * This is used for the migrated users, to only show the vip-benefit-modal once
   *
   * @returns {Promise}
   */
  setFirstLogin() {
    const endpoint = `${this.baseUrl}tbacustomers/-/firstlogin`;

    const body = {
      attributes: [
        { id: 'firstLogin', value: 'true' },
      ],
    };

    return restCall(endpoint,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  /**
   * remove message
   *
   * @param {string} param
   * @returns {Promise<any | never>}
   */
  setRemoveMessage(param) {
    const endpoint = `${this.baseUrl}tbabasket/attributes`;

    const body = {
      attributes: [
        { id: param, value: null },
      ],
    };

    return restCall(endpoint,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        credentails: 'omit',
      })
      .then(checkFetchReturnStatus)
      .catch(this.handleError);
  }

  /**
   * sets a review on an object
   *
   * @param {Object} obj
   * @param {string} obj.sku
   * @param {Object} obj.reviewObject
   * @returns {Promise}
   */
  setReview({ sku, reviewObject }) {
    if (!sku) {
      return Promise.reject({ msg: 'No SKU' });
    }
    const endpoint = `${this.baseUrl}products/${sku}/reviews`;
    return restCall(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reviewObject),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * Get all reviews from a sku
   *
   * @param {string} sku
   * @returns {Promise}
   */
  getReviews({ sku }) {
    if (!sku) {
      return Promise.reject({ msg: 'No SKU' });
    }
    const endpoint = `${this.baseUrl}products/${sku}/reviews`;
    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  /**
   * get a specific review by id for a sku
   *
   * @param {Object} obj
   * @param {string|number} obj.sku
   * @param {string|number} obj.id
   * @returns {Promise}
   */
  getReview({ sku, id }) {
    if (!sku || !id) {
      return Promise.reject({ msg: 'No sku or id' });
    }
    // /products/<sku>/reviews/<id>
    const endpoint = `${this.baseUrl}products/${sku}/reviews/${id}`;
    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }
  /**
   * Get the search suggestions from the backend
   *
   * @param {string} searchTerm
   * @returns {Promise}
   */
  getSearchSuggestions(searchTerm) {
    const endpoint = `${this.baseUrl}advsuggest?searchQuery=${searchTerm}`;
    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then((response) => {
        return response.status === 200 ? response.json() : Promise.reject(response);
      })
      .catch(this.handleError);
  }
  /**
   * Get the search suggestions from the backend
   *
   * @param {object} filterState
   * @returns {string} a URL that can be used to create a GET request
   */
  _convertFilterStateToUrl(filterState = {}) {
    // Split filter state into 3 sections
    const { selectedFilters = [], sortBy = {}, ...baseConfig } = filterState;

    // 1. Create the base string, which includes all filters with a primitive type value
    const baseFilters = Object.entries(baseConfig).filter(([key, value]) => {
      return !!(key && value);
    });
    const baseStringArray = baseFilters.map(([key, value]) => `${key}=${value}`);
    const baseString = baseStringArray.join('&');
    /*
     * 2. Create a string for selected filters. Exclude the categoryId (CategoryUUIDLevelMulti)
     * as it is already included in the baseString.
     */
    const filteredSelectedFilters = selectedFilters.filter(filter => filter.id !== 'CategoryUUIDLevelMulti').sort((a, b) => a - b);
    const selectedFiltersArray = [];
    filteredSelectedFilters.forEach((filter) => {
      const options = filter.options.sort((a, b) => a - b);
      const { id } = filter;
      const filterArray = [];
      if (!!options.length) {
        filterArray.push(`&attrName=${id}`);
      }

      options.forEach((option) => {
        filterArray.push(option.value);
      });
      selectedFiltersArray.push(filterArray.join('|'));
    });

    const selectedFiltersString = selectedFiltersArray.join('');

    // 3. Create a string for the sorting
    let sortingString = '';
    if (!!sortBy.value && !!sortBy.direction) {
      sortingString = `&sortingVal=${sortBy.value}&sortingDir=${sortBy.direction}`;
    }

    // Join all 3 strings
    const getRequestParams = [
      baseString,
      selectedFiltersString,
      sortingString,
    ].filter(Boolean).join('');

    return `/getFilters?${getRequestParams}`;
  }


  getNewFilteredContent(filterState = {}) {
    const { selectedFilters = [], searchParameter = null  } = filterState;
    const { sortBy = {}, ...json  } = filterState;
    if (sortBy.value) {
      json.sorting = sortBy;
    }

    const payload = {
      ...json,
      selectedFilters: selectedFilters.map((filter) => {
        const { id, options, type } = filter;
        return { id, options, type };
      }),
    };

    let url = `${this.baseUrl}advfilter`;
    let getUrl = '';
    let body = JSON.stringify(payload);
    let method = 'POST';
    let charLimitReached = false;

    // If one of these ID's is present in the selected filters, make a POST request. Search parameter is also messing up the request, so we also need to have POST
    const postFilterIDs = ['regularprice', 'thema'];
    const isGetRequest = !selectedFilters.filter((item) => {
      return postFilterIDs.includes(item.id);
    }).length > 0 && !searchParameter;

    if (isGetRequest) {
      getUrl = url + this._convertFilterStateToUrl(filterState);
      charLimitReached = getUrl.length > 2000;
    }

    if (isGetRequest && !charLimitReached) {
      method = 'GET';
      body = null;
      url = getUrl;
    }

    return restCall(url, {
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      method,
      body,
    }).then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  // NOTE: Category UUID in the filter is actually called parentCategory in the response of the pipeline (HTML jSon)/
  getStaticBreadcrumbs(categoryUUID) {
    const url = `${this.baseUrl  }breadcrumb/category?uuid=${categoryUUID}`;
    return restCall(url, {
      credentials: 'omit',
      method: 'GET',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  loadPDFDocument(url) {
    return this.pdfEngine
      .load(url, this.handleError)
      .catch(this.handleError);
  }

  pollPdf(url) {
    return restCall(url, {
      method: 'HEAD',
    }, false)
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  /**
   * Returns the gift suggestions based on user input.
   * https://wiki.novamedia.com/display/ECI/2.21+Giftpicker
   *
   * @param {Object} obj.reviewObject
   * @returns {Promise}
   */
  setGiftPickerSuggestions(answers) {
    const endpoint = `${this.baseUrl}bookarangGifts/Cadeauzoeker`;
    return restCall(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ answers }),
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus) // status check
      .then(translateToJson) // translate to json
      .catch(this.handleError);
  }

  pollGoogleBooksPreview(sku, apiKey) {
    const googleBooksUrl = `https://www.googleapis.com/books/v1/volumes?q=isbn:${sku}&key=${apiKey}`;
    return restCall(googleBooksUrl, {
      method: 'GET',
      credentials: 'omit',
    }, false)
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }

  rating(url) {
    return fetch(url)
      .then(res => res.json())
      .catch(this.handleError);
  }

  /**
   * Get all stores
   */
  getAllStores() {
    const url = `${this.baseUrl}stores`;
    return restCall(url,
      {
        method: 'GET',
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get a specific store
   *
   * @param {string} storeId
   */
  getSingleStore(storeId) {
    const url = `${this.baseUrl}stores/${storeId}`;
    return restCall(url,
      {
        method: 'GET',
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .then(response => response)
      .catch(this.handleError);
  }

  getStockInfo(sku = 'not set') {
    const url = `${this.baseUrl}stockcheck/product?sku=${sku}`;
    return restCall(url,
      {
        method: 'PUT',
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .then(response => response)
      .catch(this.handleError);
  }

  /**
   * Get all localised texts
   *
   * @returns {Promise}
   */
  getLocalisedTexts() {
    const endpoint = `${this.baseUrl}localizedText`;

    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Create Click & Collect order with Aspos
   *
   * @returns {Promise}
   */
  createCollectOrder(payload) {
    const endpoint = `${this.baseUrl}stockcheck/aspos/order/create`;
    return restCall(endpoint, {
      method: 'POST',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then(checkFetchReturnStatus)
      .catch(this.handleError);
  }

  /**
   * Add email addres to keep me posted list, so the user can be notified
   */
  addToKeepMePostedList(payload) {
    const endpoint = `${this.baseUrl}stockcheck/notifyme`;
    return restCall(endpoint, {
      method: 'POST',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then(checkFetchReturnStatus)
      .catch(this.handleError);
  }

  sendPickupRequest(quantity) {
    const endpoint = `${this.baseUrl}pickuprequest`;
    return restCall(endpoint, {
      method: 'POST',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ crates: quantity }),
    })
      .then(checkFetchReturnStatus)
      .catch(this.handleError);
  }

  /**
   * Get the order history from an array of products
   *
   * @param {array} productSkus
   */
  getProductHistory(productSkus) {
    const endpoint = `${this.odsSearchBaseUrl}orders/history`;
    return restCall(endpoint, {
      method: 'POST',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      ods: true,
      interShopBaseUrl: this.baseUrl,
      body: JSON.stringify(productSkus),
    }, false)
      .then(checkFetchReturnStatus)       // status check
      .then(response => response.json())
      .catch(this.handleError);
  }

  /**
   * Get the packingslip of a delivery as pdf file
   *
   * @returns {blob} pdf
   */
  getPackingslip(pakbonNumber) {
    const endpoint = `${this.odsBaseUrl}resources/packingslip/${pakbonNumber}`;
    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      ods: true,
      interShopBaseUrl: this.baseUrl,
    }, false)
      .then(checkFetchReturnStatus)       // status check
      .catch(this.handleError);
  }

  /**
   * Get invoice as pdf file
   *
   * @returns {blob} pdf
   */
  getInvoice(docId) {
    const endpoint = `${this.odsBaseUrl}resources/invoice/${docId}`;
    return restCall(endpoint, {
      method: 'GET',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      ods: true,
      interShopBaseUrl: this.baseUrl,
    }, false)
      .then(checkFetchReturnStatus)       // status check
      .catch(this.handleError);
  }

  /**
   * purchase user discount
   *
   * @param {String} sku
   * @returns {Promise}
   */
  purchaseDiscount(sku) {
    return restCall(`${this.baseUrl}tbacustomers/-/purchasediscount?sku=${sku}`, {
      method: 'GET',
      credentials: 'omit',
    })
      .then(checkFetchReturnStatus)       // status check
      .then(translateToJson)  // translate to json
      .catch(this.handleError);
  }

  /**
   * Update the quantity of an campaignItem
   */
  updateCampaignItemQuantity({ campaignId, quantity, url }) {
    const endpoint = url;
    const body = { quantity, cartId: campaignId };

    return restCall(endpoint,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
        ods: true,
        interShopBaseUrl: this.baseUrl,
        credentials: 'omit',
      })
      .then(checkFetchReturnStatus) // status check
      .then(response => response.json()) // translate to json
      .catch(this.handleError);
  }

  purchaseCampaign({ uuid, cartId }) {
    const endpoint = `${this.odsBaseUrl}signupcampaigns/${uuid}/orders/${cartId}`;
    return restCall(endpoint, {
      method: 'POST',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
      ods: true,
      body: JSON.stringify({ cartId }),
    })
      .then(checkFetchReturnStatus)
      .catch(this.handleError);
  }


  assignPreferredStoreToCustomer(storeId) {
    const url = `${this.baseUrl}tbacustomers/-/preferredstore?storeId=${storeId}`;
    return restCall(url, {
      method: 'PUT',
      credentials: 'omit',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(checkFetchReturnStatus) // status check
      .catch(this.handleError);
  }
}
export default restapi;
