import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Navigation from './_navigation/component';
import UspBar from './_usp-bar/component';
import AccountBar from './_account-bar/component';
import Masthead from './_masthead/component';
import SaleBar from './_sale-bar/component';
import NotificationBar from './_notification-bar/component';
import HeaderContext from './HeaderContext';
import { isBrowser } from '@utils';
import CmsApi from '@utils/api/Cms';
import { HeaderModel } from '@utils/models';
import { HEADER } from '@config';
import { useApp } from '../../ssr/shared/application-context';

import './styles/styles.scss';

const Header = (props) => {
  const [items, setItems] = useState(props.items || props.staticContext?.layout?.header.components || []);
  const appCtx = useApp();

  useEffect(() => {
    if (isBrowser) {
      if (window.__LAYOUT_DATA__) {
        const { header = {} } = window.__LAYOUT_DATA__ || [];
        setItems(header.components);
        const catItems = header?.components?.filter(cat => cat.excluded === false).map((cat) => {
          return { title: cat.title, url: cat.url };
        });
        appCtx.setCategories(catItems);
      } else {
        if (!props.items) {
          fetchHeader();
        }
      }
    }
  }, []);

  /**
   * Fetch content component from the api
   */
  const fetchHeader = () => {
    const api = new CmsApi();
    return api.fetchHeaderData()
      .then((layoutData) => {
        const header = layoutData?.[0]?.components;
        setItems(header);
      });
  };

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = ({ forceClose } = {}) => {
    setShowMobileMenu(currentState => (forceClose ? false : !currentState));
  };

  const CMSData = new HeaderModel(items);

  const componentMap = {
    accountBar: (
      <AccountBar />
    ),
    uspBar: (
      <UspBar mainUSPs={CMSData.mainUSPs} links={CMSData.uspLinks} key="usp" />
    ),
    masthead: (
      <Masthead key="masthead" />
    ),
    navigationBar: (
      <Navigation
        key="nav"
        items={CMSData.menuItems}
        additionalLinks={CMSData.uspLinks}
        showMobileMenu={showMobileMenu}
        toggleMobileMenu={toggleMobileMenu}
      />
    ),
    notificationBar: (
      <NotificationBar key="not-bar" className="desktop" />
    ),
    saleBar: (
      <SaleBar key="sale-bar" />
    ),
  };

  return (
    <HeaderContext.Provider value={{
      CMSData,
      toggleMobileMenu,
      store: props.store,
    }}>
      <header>
        {HEADER.components.map(component => componentMap[component])}
      </header>
    </HeaderContext.Provider>
  );
};

Header.propTypes = {
  staticContext: PropTypes.object,
  items: PropTypes.array,
  store: PropTypes.object,
};

export default Header;
