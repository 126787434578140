import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from '../../base_link/component';
import { Icon } from 'audamatic-ui';
import SiteRating from '../../ui_site-rating/component';
import { getDevice } from '@utils';

import './styles.scss';

const UspBar = ({ links, mainUSPs }) => {
  const [showUspBar, setShowUspBar] = useState(false);

  useEffect(() => {
    const { isLaptop, isDesktop } = getDevice();

    if (isLaptop || isDesktop) {
      setShowUspBar(true);
    }
  }, []);

  const renderUspList = () => {
    return (
      <div className="usp-list">
        {mainUSPs.map((usp, index) => {
          const { title, url } = usp;

          if (!title) {
            return null;
          }

          return (
            <Link key={index} className="usp-list-item" to={url}>
              {(<Icon name="Check" />)}
              <span>
                {title}
              </span>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <>
      {showUspBar && (
        <div className="usp-bar">
          <div className="container">
            {renderUspList()}
            <div className="additional-links">
              <SiteRating />
              {links.map(link => <Link key={link.url} to={link.url} data-test-id={`usp${link.title}`}>{link.title}</Link>)}
            </div>
          </div>
        </div>
      )}
    </>

  );
};

UspBar.propTypes = {
  links: PropTypes.array,
  mainUSPs: PropTypes.array,
};

export default UspBar;
