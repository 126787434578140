
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ErrorBoundary from '@components/ui_error-boundary/component';
import LogoHeader from '@components/base_logo-header/component';
import MinimalisticHeader from '@components/base_minimalistic-header/component';
import Header from '@components/base_header/component';
import Footer from '@components/base_footer/component';
import ChatWidget from '@components/ui_chat-widget/component';
import BaseMetaTags from './seo';
import getRoutes from './routes';
import { isBrowser, log, getVersionFromString, appStorage } from '@utils';
import store from '@utils/store';
import { getRouteComponent, getRouteId } from '@utils/routes';
import { routeChange } from '@utils/analytics';
import { checkTraffic } from '@utils/traffic';
import appendIconSprite from '@utils/appendIconSprite';
import { initLocalization } from '@utils/i18n';
import logBrand from '@utils/logBrand';
import * as config from '@config';
import AuthProvider from '@utils/authentication/AuthProvider';
import RequireAuth from '@utils/authentication/RequireAuth';
import Modal from '@components/base_modal/component';
import AppProvider from './application-context';
import { useEventListener } from '@utils/hooks';
import { APP_VERSION } from '@constants';
import Api from '@utils/api';

import '../../styles/vendor/__vendor.scss';
import '../../styles/base/__base.scss';
import '../../styles/components/__components.scss';
import '../../styles/objects/__objects.scss';

const Application = (props) => {
  const api = new Api();
  /**
   * Whenever a visitor opens a stale (inactive) tab, we'll make a request
   * to the server, to see if the active version is not outdated.
   * If it is, we reload the page to force the new version.
   */
  const getAppVersion = async () => {
    if (document?.visibilityState === 'visible') {
      const res = await api.getVersion();
      const currentVersion = appStorage.getItem(APP_VERSION);
      if (!currentVersion) {
        return;
      }

      const actualVersion = getVersionFromString(res.version);
      const knownVersion = getVersionFromString(currentVersion);

      if (actualVersion > knownVersion) {
        window.location.reload();
        appStorage.setItem(APP_VERSION, res.version);
      }
    }
  };

  useEventListener('visibilitychange', getAppVersion);

  const { isClient, i18n } = props;
  const location = useLocation();
  const [routes] = useState(getRoutes());
  const [showLayout, setShowLayout] = useState(false);
  const [showMinimalisticLayout, setShowMinimalisticLayout] = useState(false);
  const [showLogoHeader, setShowLogoHeader] = useState(false);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      logBrand();
      checkTraffic();
      appendIconSprite();
      initLocalization();
      fetchWishlist();

      // Add config vars to window for cypress
      window.cy = { ...config };
    }
    setInitialized(true);
    store.trigerOnloadAnalyticsTags();
  }, []);

  // This effect is triggered whenever a route has changed.
  useEffect(() => {
    scrollTo(0, 0);
  }, [location]);

  const hasError = isBrowser ? window.__SERVER_STATUS__ === 500 : false;

  useEffect(() => {
    const preventInstall = e => e.preventDefault();
    const register = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register(`/service-worker.js`).then(() => {
          log('SW registered');
        })
          .catch((registrationError) => {
            log('SW registration failed', registrationError);
          });
      }
    };
    // Register service worker
    if (isClient && !DEVELOPMENT) {
      window.addEventListener('load', register);

      // Prevent browser from asking to install
      window.addEventListener('beforeinstallprompt', preventInstall);
    }

    return () => {
      if (isClient && !DEVELOPMENT && 'serviceWorker' in navigator) {
        window.removeEventListener('load', register);
        window.removeEventListener('beforeinstallprompt', preventInstall);
      }
    };
  }, []);


  /**
   * Push 'routeChange' event to GTM
   * @param {string} type
   */
  const triggerPageLoad = (id) => {
    if (initialized) {
      store.pushToDataLayer(
        routeChange({
          url: location.pathname,
          title: document?.title,
          id,
        }),
      );
    }
  };

  const [wishlist, setWishlist] = useState([]);
  const fetchWishlist = () => {
    api.getWishlistSkus()
      .then((response) => {
        store.events.publish('fetchedWishlist', response);
        setWishlist(response);
      });
  };

  const renderRoutes = () => {
    return routes.map(({ path, exact, component, requiresAuthentication, useLayout = true, minimalisticLayout = false, logoHeader = false, ...rest }) => {
      let Component = component;
      let { routeId } = rest;
      if (!component) {
        Component = getRouteComponent(location.pathname);
        routeId = getRouteId(location.pathname);
      }
      return (
        <Route key={path} path={path} exact={exact} render={(props) => {
          // Trigger synthetic pageload event for GTM
          triggerPageLoad(routeId);

          // Show header/footer based on route config
          setShowLogoHeader(logoHeader);
          setShowMinimalisticLayout(minimalisticLayout);
          setShowLayout(useLayout);

          return requiresAuthentication ? (
            <RequireAuth origin={path}>
              <Component {...props} {...rest} routeId={routeId} store={store}/>
            </RequireAuth>
          ) : (
            <Component {...props} {...rest} routeId={routeId} store={store}/>
          );
        }} />
      );
    });
  };

  const renderModal = () => {
    // create empty element for the sidepanel and pop-up
    const initModal = modalClass => store.modal = modalClass;

    return (
      <div id="modal">
        <Modal initModal={initModal} />
      </div>
    );
  };

  return (
    <AppProvider i18n={i18n} wishlist={wishlist}>
      <AuthProvider>
        <div>
          <BaseMetaTags/>

          {showLayout && <Header store={store}/>}
          {showMinimalisticLayout && <MinimalisticHeader store={store}/>}
          {showLogoHeader && <LogoHeader />}

          <ErrorBoundary hasError={hasError}>
            <main style={{ minHeight: '70vh' }}>
              <Switch>
                {renderRoutes()}
              </Switch>
            </main>
            {renderModal()}
          </ErrorBoundary>

          {showLayout && <Footer />}
          {showMinimalisticLayout && <Footer minimalistic={true}/>}

          <ChatWidget store={store}/>
        </div>
      </AuthProvider>
    </AppProvider>
  );
};

Application.propTypes = {
  isClient: PropTypes.bool,
  i18n: PropTypes.object,
};

export default Application;
